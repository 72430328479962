export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-budget-list/:pageId',
        component: () => import('@/views/page/company/procurement/budget/List.vue'),
        name: 'project-budget-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-budget-add/:pageId',
        component: () => import('@/views/page/company/procurement/budget/Add.vue'),
        name: 'project-budget-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-budget-add/:pageId/:budgetId',
        component: () => import('@/views/page/company/procurement/budget/Add.vue'),
        name: 'project-budget-edit',
        meta: {
            requiresAuth: true
        }
    },
    // REPORT
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-overview/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/budget-overview/List.vue'),
        name: 'budget-overview',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/budget-actual/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/budget-actual/List.vue'),
        name: 'budget-actual',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-budget-actual/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/project-bva/List.vue'),
        name: 'project-bva',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/donor-report-bva/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/donor-report-bva/List.vue'),
        name: 'donor-report-bva',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/current-period-bva/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/current-period-bva/List.vue'),
        name: 'current-period-bva',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-lifecycle-bva/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/project-lifecycle-bva/List.vue'),
        name: 'project-lifecycle-bva',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-lifecycle-boq/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/project-lifecycle-boq/List.vue'),
        name: 'project-lifecycle-boq',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/boq-status-report/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/boq-status-report/List.vue'),
        name: 'boq-status-report',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/project-tracker/:pageId',
        component: () => import('@/views/page/company/procurement/budget/report/project-tracker/List.vue'),
        name: 'project-tracker',
        meta: {
            requiresAuth: true
        }
    }
]
