export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-list/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/List.vue'),
        name: 'requisition-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-add/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/Add.vue'),
        name: 'requisition-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-pending/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/pending/List.vue'),
        name: 'requisition-pending',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-approval/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/approval/List.vue'),
        name: 'requisition-approval',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-approval/:pageId/review',
        component: () => import('@/views/page/company/procurement/requisition/approval/View.vue'),
        name: 'requisition-review',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-approval/:pageId/details',
        component: () => import('@/views/page/company/procurement/requisition/approval/View.vue'),
        name: 'requisition-view',
        meta: {
            requiresAuth: true
        }
    },
    // PR
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-allocation-list/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/pr-allocation/List.vue'),
        name: 'pr-allocation-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-allocation-add/:pageId/:requisitionId',
        component: () => import('@/views/page/company/procurement/requisition/pr-allocation/Add'),
        name: 'pr-allocation-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-register/:pageId',
        component: () => import('@/views/page/company/procurement/requisition/register/List'),
        name: 'requisition-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition/:pageId/print/:requisitionId',
        component: () => import('@/views/page/company/procurement/requisition/Print.vue'),
        name: 'requisition-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fund-requisition-list/:pageId',
        component: () => import('@/views/page/company/procurement/fund-requisition/List.vue'),
        name: 'fund-requisition-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fund-requisition-add/:pageId',
        component: () => import('@/views/page/company/procurement/fund-requisition/Add.vue'),
        name: 'fund-requisition-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-fr-from-pr/:pageId',
        component: () => import('@/views/page/company/procurement/fund-requisition/PendingPRList.vue'),
        name: 'pending-fr-from-pr',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fund-requisition/:pageId/print/:requisitionId',
        component: () => import('@/views/page/company/procurement/fund-requisition/Print.vue'),
        name: 'fund-requisition-print',
        meta: {
            requiresAuth: true
        }
    }
]