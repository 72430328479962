export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/company-info/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/CompanyInfo.vue'),
        name: 'company-info',
        meta: {
            requiresAuth: true
        }
    },
    // {
    //     path: '/company/:companyId/module/:moduleId/menu/:menuId/business-list/:pageId',
    //     component: () => import('@/views/error/UnderConstruction.vue'),
    //     name: 'business-list',
    //     meta: {
    //         requiresAuth: true
    //     }
    // },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/branch-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/BranchList.vue'),
        name: 'branch-list',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/department-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/DepartmentList.vue'),
        name: 'department-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/designation-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/DesignationList.vue'),
        name: 'designation-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/employee-role-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/RoleList.vue'),
        name: 'employee-role-list',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/program-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/ProgramList.vue'),
        name: 'program-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cost-centre-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/CostCentreList.vue'),
        name: 'cost-centre-list',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/workflow-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/approval-matrix/WorkflowApprovalList.vue'),
        name: 'approval-matrix',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/workflow-add/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/approval-matrix/WorkflowAdd.vue'),
        name: 'approval-matrix-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/workflow/:workflowId/edit/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/approval-matrix/WorkflowAdd.vue'),
        name: 'approval-matrix-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/activity-list/:pageId',
        component: () => import('@/views/page/company/procurement/organization-settings/admin-setup/ActivityList.vue'),
        name: 'activity-list',
        meta: {
            requiresAuth: true
        }
    },
]
