import { createStore } from 'vuex'
import dashboard from './modules/dashboard'
import company from './modules/company'
import journal from './modules/journal'
import chartAccount from './modules/chartAccount'
import user from './modules/user'

export default createStore({
  modules: {
    dashboard,
    company,
    journal,
    chartAccount,
    user
  },
  state: {
    progress: 0,
    loading: false,
    isModalOpen: false,
    isModalOpenTwo: false,
    isModalOpenThree: false,
    isModalOpenFour: false,
    paginationData: {
      from: 1,
      records: 0,
      perPage: 0,
      currentPage: 0,
    },
    secondPaginationData: {
      from: 1,
      records: 0,
      perPage: 0,
      currentPage: 0,
    }
  },
  mutations: {
    updateProgress: (state, value) => {
      state.progress = value
    },
    updateUserNameToLocalStorageAfterChange: (state, name) => {
      let userInfo = JSON.parse(localStorage.getItem('user'))
      userInfo.name = name
      localStorage.setItem('user', JSON.stringify(userInfo))
    },
    setLoading: (state, value) => state.loading = value,
    setPaginationData: (state, data, stateName = 'paginationData') => state[stateName] = data,
    resetPagination (state, stateName = 'paginationData') {
      state[stateName] = {
        from: 1,
        records: 0,
        perPage: 0,
        currentPage: 0,
      }
    }
  },
  actions: {
  }
})
