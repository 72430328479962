export default [
    {
        path: '/company/:companyId/module/:moduleId/role-permission/:roleId',
        component: () => import('@/views/page/company/role/RolePermission.vue'),
        name: 'role-permission',
        meta: {
            requiresAuth: true
        }
    }, {
        path: '/company/:companyId/module/:moduleId/user/:userId/role/:roleId/permission-assign',
        component: () => import('@/views/page/company/role/UserRolePermission.vue'),
        name: 'user-role-permission',
        meta: {
            requiresAuth: true
        }
    }
]
