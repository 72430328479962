const state = () => ({
	chartAccounts:[]
})

const getters = {
	getChartAccount: state => state.chartAccounts,
}

const mutations = {
	setChartAccount: (state, payload) => state.chartAccounts = payload
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}