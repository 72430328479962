export default [
    {
        path: '/company-setting/:companyId/module/:moduleId',
        name: 'company-settings',
        component: () => import('@/views/page/company/CompanySettings.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company-setting/:companyId/module/:moduleId/menu-design',
        name: 'MenuDesign',
        component: () => import('@/views/page/company/MenuDesign.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/company-setting/:companyId/module/:moduleId/menu-sorting',
        name: 'MenuSorting',
        component: () => import('@/views/page/company/MenuSorting.vue'),
        meta: {
            requiresAuth: true,
        }
    }
]
