<template>
     <div class="main-menu menu-fixed menu-light menu-accordion menu-shadow expanded" data-scroll-to-active="true">
        <div class="navbar-header expanded">
            <ul class="nav navbar-nav flex-row">
                <li style="wi" class="nav-item me-auto">
                    <SidebarLoader v-if="loading" class="mt-2"/>
                    <div v-else @click="goToCompany" style="cursor:pointer" class="d-flex align-items-center">
                        <img class="mt-1 me-2" width="40" :src="selectedModules?.icon" alt="">
                        <p class="mb-0 mt-1">{{selectedModules?.name}}</p>
                    </div>
                </li>
                <li class="nav-item nav-toggle"><a class="nav-link modern-nav-toggle pe-0" data-bs-toggle="collapse"><i class="d-block d-xl-none text-primary toggle-icon font-medium-4" data-feather="x"></i><i class="d-none d-xl-block collapse-toggle-icon font-medium-4  text-primary" data-feather="disc" data-ticon="disc"></i></a></li>
            </ul>
        </div>
        <div class="shadow-bottom"></div>
        <div class="main-menu-content">
            <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
                <!-- <li class=" nav-item"><a class="d-flex align-items-center" href="index.html"><i data-feather="home"></i><span class="menu-title text-truncate" data-i18n="Dashboards">Dashboards</span><span class="badge badge-light-warning rounded-pill ms-auto me-1">2</span></a>
                    <ul class="menu-content">
                        <li><a class="d-flex align-items-center" href="dashboard-analytics.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="Analytics">Analytics</span></a>
                        </li>
                        <li class="active"><a class="d-flex align-items-center" href="dashboard-ecommerce.html"><i data-feather="circle"></i><span class="menu-item text-truncate" data-i18n="eCommerce">eCommerce</span></a>
                        </li>
                    </ul>
                </li> -->
                <li class=" navigation-header"><span data-i18n="Apps &amp; Pages">Menus</span><i data-feather="more-horizontal"></i>
                </li>
                <!-- <li  class="nav-item">
                    <a class="d-flex align-items-center">
                        <i data-feather="mail"></i>
                        <span class="menu-title text-truncate" data-i18n="Email">Email</span>
                    </a>
                </li> -->
                <div class="px-2" v-if="loading">
                    <SidebarLoader v-for="n in 10" :key="n"/>
                </div>
                <template v-else>
                    <template v-for="(item, i) in sidebar" :key="i">
                        <li v-if="item.status == 'active'" :class="[item.id == $route.params.menuId ? 'active' : '']" class="nav-item">
                            <MenuItem :item="item"/>
                        </li>
                    </template>

                </template>
            </ul>
        </div>
    </div>
</template>

<script>
import {menu} from '@/data/menu'
import MenuItem from '@/components/layout/dashboard/MenuItem'
import {mapGetters} from 'vuex'
import SidebarLoader from "@/components/atom/loader/SidebarLoader.vue";


export default {
    name: 'SideNavbar',

    components: {
        MenuItem,
        SidebarLoader
    },

    data: () => ({
        menu
    }),

    computed: {
        ...mapGetters({
            sidebar: 'company/getSidebar',
            companyLogo: 'company/getCompanyLogo',
            modules: 'company/getModules'
        }),
        moduleId() {
            return parseInt(this.$route.params.moduleId)
        },
        selectedModules() {
            // return this.modules[this.moduleId-1]
            return this.modules.find(module => module.id == this.moduleId)
        }
    },

    methods: {
        goToCompany() {
            this.$router.push(
                {
                    name: 'Company',
                    params:{
                        companyId: this.$route.params.companyId,
                        moduleId: this.moduleId
                    }
                }
            )
        }
    },

    props: {
        loading: Boolean
    }
}
</script>
<style scoped>

.main-menu.menu-light .navigation > li.active > a {
    background: #f0f2f5;
    box-shadow: none;
    color: #7D7D7D;
}
/* .main-menu.menu-light .navigation > li > a:hover {
    background: #f0f2f5;
} */
</style>
