export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-contra-list/:pageId',
        component: () => import('@/views/page/company/ngo/voucher/contra/List.vue'),
        name: 'np-contra-voucher-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-contra/:pageId/create-contra',
        component: () => import('@/views/page/company/ngo/voucher/contra/Create.vue'),
        name: 'np-contra-voucher-create',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-contra/:pageId/edit-contra/:contraId',
        component: () => import('@/views/page/company/ngo/voucher/contra/Edit.vue'),
        name: 'np-contra-voucher-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-contra/:pageId/print-contra/:contraId',
        component: () => import('@/views/page/company/ngo/voucher/contra/Print.vue'),
        name: 'ngo-contra-voucher-print',
        meta: {
            requiresAuth: true
        }
    }
]
