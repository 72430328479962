<template>
    <div class="skeleton">
        <div class="s-img"></div>
        <div class="s-line first"></div>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style scoped>
@keyframes skeleton-animation
{
  
  0%
  {
    right: 100%;
  }
  
  50%
  {
    right: -128px;
  }
  
  100%
  {
    right: 100%;
  }
  
}

.skeleton
{
  overflow: hidden;
  width: 300px;
  height: 20px;
  display: grid;
  grid-template-columns: 70px 150px;
  grid-gap: 8px;
  position: relative;
  margin-bottom: 16px;
}

.skeleton:before
{
  display: block;
  position: absolute;
  width: 50px;
  height: 50px;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
  content: "";
  animation: skeleton-animation 1.25s infinite;
}

.skeleton .s-img
{
  width: 60px;
  height: 20px;
  background: #eee;
  grid-row: 1 / 4;
}

.skeleton .s-line
{
  height: 20px;
  background: #eee;
}

.skeleton .s-line.first { width: 100% }

.skeleton .s-line.second { width: 75% }

.skeleton .s-line.third { width: 50% }
</style>
