export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receipt-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/receipt/List.vue'),
        name: 'receipt-voucher-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receipt/:pageId/create-receipt',
        component: () => import('@/views/page/company/core-accounting/voucher/receipt/Create.vue'),
        name: 'CreateReceipt',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/receipt/:pageId/edit-receipt/:receiptId',
        component: () => import('@/views/page/company/core-accounting/voucher/receipt/Edit.vue'),
        name: 'EditReceipt',
        meta: {
            requiresAuth: true
        }
    }
]