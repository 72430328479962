export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/business-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/account-setting/business/List.vue'),
        name: 'business-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/business-list/:pageId/create-business',
        component: () => import('@/views/page/company/core-accounting/account-setting/business/Create.vue'),
        name: 'CreateBusiness',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/business-list/:pageId/edit-business/:businessId',
        component: () => import('@/views/page/company/core-accounting/account-setting/business/Edit.vue'),
        name: 'EditBusiness',
        meta: {
            requiresAuth: true
        }
    },
]