export default [
    {
        path: '/company/:companyId/module/:moduleId/user-role',
        component: () => import('@/views/page/company/user/Role.vue'),
        name: 'user-role',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/user-acceptance',
        component: () => import('@/views/page/company/user/UserAcceptance.vue'),
        name: 'user-acceptance',
        meta: {
            layout: 'auth'
        }
    },
]