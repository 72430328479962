import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import veeValidation from './services/plugins/validation.js'
import globalComponents from './services/plugins/globalComponents.js'
import store from './store'
import mitt from 'mitt'

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import MasterLayout from './components/layout/MasterLayout';
import AuthLayout from './components/layout/AuthLayout';
import ProfileLayout from './components/layout/ProfileLayout';
import WithoutSidebarLayout from './components/layout/WithoutSidebarLayout';
import ContactLayout from "@/components/layout/ContactLayout";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

const emitter = mitt()
const app = createApp(App);

app.component('master-layout', MasterLayout);
app.component('auth-layout', AuthLayout);
app.component('profile-layout', ProfileLayout);
app.component('withoutsidebar-layout', WithoutSidebarLayout);
app.component('contact-layout', ContactLayout);
app.component("v-select", vSelect);

app.config.globalProperties.emitter = emitter
app.provide('emitter', emitter);
app
.use(store)
.use(router)
.use(veeValidation)
.use(globalComponents)
.use(Toast)
.mount('#app')
