export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contra-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/contra/List.vue'),
        name: 'contra-voucher-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contra/:pageId/create-contra',
        component: () => import('@/views/page/company/core-accounting/voucher/contra/Create.vue'),
        name: 'CreateContra',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/contra/:pageId/edit-contra/:contraId',
        component: () => import('@/views/page/company/core-accounting/voucher/contra/Edit.vue'),
        name: 'EditContra',
        meta: {
            requiresAuth: true
        }
    }
]