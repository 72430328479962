export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/import-tracker/:pageId',
        component: () => import('@/views/page/company/procurement/import-tracker/ImportTrackerList.vue'),
        name: 'import-tracker',
        meta: {
            requiresAuth: true
        }
    }
]
