<template>
  <nav
    :class="{'l-0': isExpanded }"
    class="
      header-navbar
      navbar navbar-expand-lg
      align-items-center
      floating-nav
      navbar-light navbar-shadow
    "
  >
    <div class="navbar-container d-flex content justify-content-between">
      <div class="bookmark-wrapper d-flex align-items-center">
        <div v-if="showMiaLogo" class="h-40">
          <a class="nav-link" href="/">
            <img src="/app-assets/images/myAccLogo.png" style="width: 150px" alt="">
          </a>
        </div>
        <ul v-if="showToggle" class="nav navbar-nav d-xl-none">
            <li class="nav-item"><a class="nav-link menu-toggle" href="#"><i class="fas fa-bars"></i></a></li>
        </ul>
        <div v-if="showCompanyName" class="d-sm-block">
            <h4 class="desktop-company-name">&nbsp;{{ company.name }}</h4>
            <h4 :title="company.name" v-if="company?.name?.length" class="mobile-company-name">&nbsp;{{ company.name.slice(0,13) }}...</h4>
        </div>
        <!--    <ul class="nav navbar-nav bookmark-icons">
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-email.html" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Email"><i class="ficon" data-feather="mail"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-chat.html" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Chat"><i class="ficon" data-feather="message-square"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-calendar.html" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Calendar"><i class="ficon" data-feather="calendar"></i></a></li>
                    <li class="nav-item d-none d-lg-block"><a class="nav-link" href="app-todo.html" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Todo"><i class="ficon" data-feather="check-square"></i></a></li>
                </ul> -->
        <!--  <ul class="nav navbar-nav">
                    <li class="nav-item d-none d-lg-block"><a class="nav-link bookmark-star"><i class="ficon text-warning" data-feather="star"></i></a>
                        <div class="bookmark-input search-input">
                            <div class="bookmark-input-icon"><i data-feather="search"></i></div>
                            <input class="form-control input" type="text" placeholder="Bookmark" tabindex="0" data-search="search">
                            <ul class="search-list search-list-bookmark"></ul>
                        </div>
                    </li>
                </ul> -->
      </div>

      <ul v-if="showMiddleMenu" class="nav navbar-nav align-items-center d-none d-md-inline-flex">
        <li class="nav-item">
          <router-link :to="{name: 'profile'}" class="nav-link nav-link-style d-flex btn btn-outline-secondary p-10">
            <i class="fas fa-home mb-0 mr-5"></i>
            <span class="h5 d-sm-none d-xl-inline-block m-0">Home</span>
          </router-link>
        </li>
        <li class="nav-item ms-4">
          <router-link :to="{name: 'Proadvisor'}" class="nav-link nav-link-style d-flex btn btn-outline-secondary p-10">
            <i class="fas fa-chalkboard-teacher mb-0 mr-5"></i>
            <span class="h5 d-sm-none d-xl-inline-block m-0">Pro Adviser</span>
          </router-link>
        </li>
        <li class="nav-item ms-4">
          <router-link :to="{name: 'contact.user.index'}" class="nav-link nav-link-style d-flex btn btn-outline-secondary p-10">
            <i class="fas fa-address-book mb-0 mr-5"></i>
            <span class="h5 d-sm-none d-xl-inline-block m-0">My Contacts</span>
          </router-link>
        </li>
      </ul>

      <ul class="nav navbar-nav align-items-center">
        <!-- <li class="nav-item dropdown dropdown-language"><a class="nav-link dropdown-toggle" id="dropdown-flag" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="flag-icon flag-icon-us"></i><span class="selected-language">English</span></a>
                    <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdown-flag"><a class="dropdown-item" href="#" data-language="en"><i class="flag-icon flag-icon-us"></i> English</a><a class="dropdown-item" href="#" data-language="fr"><i class="flag-icon flag-icon-fr"></i> French</a><a class="dropdown-item" href="#" data-language="de"><i class="flag-icon flag-icon-de"></i> German</a><a class="dropdown-item" href="#" data-language="pt"><i class="flag-icon flag-icon-pt"></i> Portuguese</a></div>
                </li>
                <li class="nav-item d-none d-lg-block"><a class="nav-link nav-link-style"><i class="ficon" data-feather="moon"></i></a></li>
                <li class="nav-item nav-search"><a class="nav-link nav-link-search"><i class="ficon" data-feather="search"></i></a>
                    <div class="search-input">
                        <div class="search-input-icon"><i data-feather="search"></i></div>
                        <input class="form-control input" type="text" placeholder="Explore Vuexy..." tabindex="-1" data-search="search">
                        <div class="search-input-close"><i data-feather="x"></i></div>
                        <ul class="search-list search-list-main"></ul>
                    </div>
                </li> -->
        <!--  <li class="nav-item dropdown dropdown-cart me-25"><a class="nav-link" href="#" data-bs-toggle="dropdown"><i class="ficon" data-feather="shopping-cart"></i><span class="badge rounded-pill bg-primary badge-up cart-item-count">6</span></a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 me-auto">My Cart</h4>
                                <div class="badge rounded-pill badge-light-primary">4 Items</div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list">
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="/app-assets/images/pages/eCommerce/1.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> Apple watch 5</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$374.90</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="/app-assets/images/pages/eCommerce/7.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> Google Home Mini</a></h6><small class="cart-item-by">By Google</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="3">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$129.40</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="/app-assets/images/pages/eCommerce/2.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> iPhone 11 Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="2">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$699.00</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="/app-assets/images/pages/eCommerce/3.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> iMac Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$4,999.00</h5>
                                </div>
                            </div>
                            <div class="list-item align-items-center"><img class="d-block rounded me-1" src="/app-assets/images/pages/eCommerce/5.png" alt="donuts" width="62">
                                <div class="list-item-body flex-grow-1"><i class="ficon cart-item-remove" data-feather="x"></i>
                                    <div class="media-heading">
                                        <h6 class="cart-item-title"><a class="text-body" href="app-ecommerce-details.html"> MacBook Pro</a></h6><small class="cart-item-by">By Apple</small>
                                    </div>
                                    <div class="cart-item-qty">
                                        <div class="input-group">
                                            <input class="touchspin-cart" type="number" value="1">
                                        </div>
                                    </div>
                                    <h5 class="cart-item-price">$2,999.00</h5>
                                </div>
                            </div>
                        </li>
                        <li class="dropdown-menu-footer">
                            <div class="d-flex justify-content-between mb-1">
                                <h6 class="fw-bolder mb-0">Total:</h6>
                                <h6 class="text-primary fw-bolder mb-0">$10,999.00</h6>
                            </div><a class="btn btn-primary w-100" href="app-ecommerce-checkout.html">Checkout</a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item dropdown dropdown-notification me-25"><a class="nav-link" href="#" data-bs-toggle="dropdown"><i class="ficon" data-feather="bell"></i><span class="badge rounded-pill bg-danger badge-up">5</span></a>
                    <ul class="dropdown-menu dropdown-menu-media dropdown-menu-end">
                        <li class="dropdown-menu-header">
                            <div class="dropdown-header d-flex">
                                <h4 class="notification-title mb-0 me-auto">Notifications</h4>
                                <div class="badge rounded-pill badge-light-primary">6 New</div>
                            </div>
                        </li>
                        <li class="scrollable-container media-list"><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar"><img src="/app-assets/images/portrait/small/avatar-s-15.jpg" alt="avatar" width="32" height="32"></div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Congratulation Sam 🎉</span>winner!</p><small class="notification-text"> Won the monthly best seller badge.</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar"><img src="/app-assets/images/portrait/small/avatar-s-3.jpg" alt="avatar" width="32" height="32"></div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">New message</span>&nbsp;received</p><small class="notification-text"> You have 10 unread messages</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-danger">
                                            <div class="avatar-content">MD</div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Revised Order 👋</span>&nbsp;checkout</p><small class="notification-text"> MD Inc. order updated</small>
                                    </div>
                                </div>
                            </a>
                            <div class="list-item d-flex align-items-center">
                                <h6 class="fw-bolder me-auto mb-0">System Notifications</h6>
                                <div class="form-check form-check-primary form-switch">
                                    <input class="form-check-input" id="systemNotification" type="checkbox" checked="">
                                    <label class="form-check-label" for="systemNotification"></label>
                                </div>
                            </div><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-danger">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="x"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Server down</span>&nbsp;registered</p><small class="notification-text"> USA Server is down due to high CPU usage</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-success">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="check"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">Sales report</span>&nbsp;generated</p><small class="notification-text"> Last month sales report generated</small>
                                    </div>
                                </div>
                            </a><a class="d-flex" href="#">
                                <div class="list-item d-flex align-items-start">
                                    <div class="me-1">
                                        <div class="avatar bg-light-warning">
                                            <div class="avatar-content"><i class="avatar-icon" data-feather="alert-triangle"></i></div>
                                        </div>
                                    </div>
                                    <div class="list-item-body flex-grow-1">
                                        <p class="media-heading"><span class="fw-bolder">High memory</span>&nbsp;usage</p><small class="notification-text"> BLR Server using high memory</small>
                                    </div>
                                </div>
                            </a>
                        </li>
                        <li class="dropdown-menu-footer"><a class="btn btn-primary w-100" href="#">Read all notifications</a></li>
                    </ul>
                </li> -->
        <!-- <li class="nav-item">
            <router-link to="#" class="nav-link nav-link-style py-0 px-0">
                <i class="fa-brands fa-buromobelexperte me-1 h1  mx-0 my-0"></i>
            </router-link>
        </li> -->

        <li class="nav-item dropdown dropdown-modules" style="padding-right: 10px" v-show="modules.length">
          <a class="nav-link dropdown-toggle"
             id="dropdown-modules"
             href="#"
             data-bs-toggle="dropdown"
             aria-haspopup="true"
             aria-expanded="false"
          >
            <i class="ficon" data-feather="grid"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-end text-center"
               aria-labelledby="dropdown-modules" style="width: 30rem">
            <div class="row" style="margin-left: 3px;margin-right: 3px">
              <div class="col-md-4 col-sm-4 col-6 text-center" style="height: 6rem" v-for="(item, i) in modules" :key="i">
                <a
                  class="dropdown-item d-flex justify-content-center flex-column"
                  :href="getModuleUrl(this.company.id, item)"
                  style="height: 100%">
                  <div>
                    <img :src="item.icon" width="40" alt="icons"/>
                  </div>
                  <div >
                    <p  style="margin-top: auto">{{ item.name }}</p>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </li>

        <li v-if="showProfileMenu" class="nav-item dropdown d-inline d-md-none">
          <a
            id="dropdown-menu"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span>
                <img
                    src="/app-assets/images/icons/menu.svg"
                    alt="avatar"
                    height="30"
                    width="30"
                />
            </span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown-menu"
          >
            <router-link to="/home" class="dropdown-item">
              <a> <i class="fas fa-home mb-0 h5"></i> Profile </a>
            </router-link>

            <router-link :to="{name: 'Proadvisor'}" class="dropdown-item">
              <a> <i class="fas fa-chalkboard-teacher mb-0 h5"></i> ProAdvisor </a>
            </router-link>

            <router-link :to="{name: 'contact.user.index'}" class="dropdown-item">
              <a> <i class="fas fa-address-book mb-0 h5"></i> My Contacts </a>
            </router-link>
          </div>
        </li>
        <li class="nav-item dropdown dropdown-user">
          <a
            class="nav-link dropdown-toggle dropdown-user-link"
            id="dropdown-user"
            href="#"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="user-nav d-sm-flex d-none">
              <span class="user-name fw-bolder">{{ user.name }}</span>
            </div>
            <span class="avatar"
              ><img
                class="round"
                :src="profile.photo"
                alt="avatar"
                height="40"
                width="40" /><span class="avatar-status-online"></span
            ></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="dropdown-user"
          >
            <router-link to="/profile" class="dropdown-item">
              <a> <img style="margin-right: 10px;" width="14" src="/app-assets/images/icons/home.svg" alt=""> Home </a>
            </router-link>
            <router-link :to="{'name': 'contact.myProfile.show'}" class="dropdown-item">
              <a> <img style="margin-right: 10px;" width="14" src="/app-assets/images/icons/user.svg" alt=""> My Profile </a>
            </router-link>
            <a @click.prevent="logout" class="dropdown-item"
              ><img style="margin-right: 10px;" width="14" src="/app-assets/images/icons/power.svg" alt=""> Logout</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import handleSidebar from "@/services/modules/sidebar";

export default {
  name: "TopNav",

  methods: {
      logout() {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          this.$router.push('/login')
      }
  },

  computed: {
      isRouteProfile() {
          return this.$route.name == 'profile'
      },
      icon(){
          return "fas fa-gear"
      }
  },

  props: {
      showMiddleMenu: {
        default: false
      },
      showCompanyName: {
        default: false
      },
      showToggle: {
        default: false
      },
      showSetting: {
        default: false
      },
      showMiaLogo: {
        default: false
      },
      isExpanded: {
        default: false
      },
      showProfileMenu: {
        default: false
      }
  },

  setup() {
    const store = useStore();

    const {
      getModuleUrl
    } = handleSidebar()
    return {
      getModuleUrl,
      user: computed(() => store.state.user.user),
      profile: computed(() => store.state.user.profile),
      company: computed(() => store.getters['company/getCompany']),
      modules: computed(() => store.getters['company/getModules']),
    };
  },
};
</script>

<style scoped>
.l-0 {
    left: 0 !important;
}
.mobile-company-name{
        display:none;
}

.nav-link.btn-outline-secondary{
  border: none !important;
  border-color: unset !important;
}

.nav-link.btn-outline-secondary:hover{
  background-color: rgb(0 0 0 / 10%) !important;
}
.nav-link span{
  margin-left: 0.5rem;
}

@media screen and (max-width:480px){
    .desktop-company-name{
        display:none;
    }
    .mobile-company-name{
        display:inline !important;
    }
}

</style>
