export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np/receipt-list/:pageId',
        component: () => import('@/views/page/company/ngo/voucher/receipt/List.vue'),
        name: 'receipt-voucher-np',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np/receipt/:pageId/create-receipt',
        component: () => import('@/views/page/company/ngo/voucher/receipt/Create.vue'),
        name: 'receipt-voucher-np-create',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np/receipt/:pageId/edit-receipt/:receiptId',
        component: () => import('@/views/page/company/ngo/voucher/receipt/Edit.vue'),
        name: 'receipt-voucher-np-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-receipt/:pageId/print-receipt/:receiptId',
        component: () => import('@/views/page/company/ngo/voucher/receipt/Print.vue'),
        name: 'ngo-receipt-voucher-print',
        meta: {
            requiresAuth: true
        }
    }
]
