<template>
    <router-link
        :to="{
            name: 'CompanySubmenu',
            params:{menuId: item.id}
        }"
        @click="handleClick" class="d-flex align-items-center"
    >
        <img :src="item.is_dashboard ? '/app-assets/images/icons/dashboard.jpeg' : item.icon" width="25" alt="" class="me-1">
        <span class="menu-item text-truncate" data-i18n="List">{{ item.name }}</span>
    </router-link>
</template>

<script>
export default {
    name: 'MenuItem',
    props: {
        item: {
            required: true
        }
    },
    methods: {
        handleClick() {
            let width = document.body.clientWidth;
            if(width <= 1199) {
                const btn = document.querySelector('.modern-nav-toggle');
                if(btn) btn.click();
            }
        }
    }
}
</script>
