
import {includes, replace} from 'lodash'
import Network from '@/services/network'
import { ref } from 'vue'
import useDate from '@/services/utils/day'
import figureFormatter from '@/services/utils/figureFormatter.js'
// import {useQuery} from '@/services/utils/query.js'

const {
    firstDateOfQuarter,
    lastDateOfQuarter,
    lastDateOfYear,
    firstDateOfYear,
    firstDateOfLastYear,
    lastDateOfLastYear,
    firstDateOfMonth,
    lastDateOfMonth,
    firstDateOfLastMonth,
    lastDateOfLastMonth
} = useDate()

const network = new Network

export function useStatistics(params) {
    const receipt_and_payment_data = ref({
        data: [],
        dateRange: ''
    })
    const salesData = ref({
        data: [],
        dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
        key: 'sales_date'
    })
    const statisticsItem = ref({
        income: {
            key: 'income_date',
            name: 'Income',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'income'
        },
        expense: {
            key: 'expense_date',
            name: 'Expense',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image :'expense'
        },
        profit_and_loss: {
            key: 'profit_and_loss_date',
            name: 'Profit & Loss',
            value: 0,
            isFilterable: true,
            dateRange: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `,
            image: 'profit_and_loss'
        },
        receivable: {
            key: 'receivable',
            name: 'Receivable',
            value: 0,
            url: 'receivable-register-dashboard',
            image: 'receivable'
        },
        payable: {
            key: 'payable',
            name: 'Payable',
            value: 0,
            url: 'payable-register-dashboard',
            image: 'payable'
        },
        cash_and_bank: {
            key: 'cash_and_bank',
            name: 'Cash & Bank',
            value: 0,
            url: 'cash-bank-register-dashboard',
            image: 'cash_and_bank'
        }
    })
    const {commaFormat} = figureFormatter()

    const makeStatistics = (statisticsData, notIncludeArr) => {
        let statistics=[]
        Object.keys(statisticsData).forEach((key) => {
            if(!includes(notIncludeArr, key)) {
                statistics.push(
                    {
                        name: replace(key, /[\_]/g, ' '), 
                        value: commaFormat(statisticsData[key]),
                        key: key
                    }
                )
            }
        })
        return statistics
    }

    function setValue(payload) {
        const {
            cash_and_bank,
            expense,
            income,
            payable,
            profit_and_loss,
            receipt_and_payment,
            receivable,
            sales
        } = payload
    
        statisticsItem.value.income.value = income
        statisticsItem.value.cash_and_bank.value = cash_and_bank
        statisticsItem.value.expense.value = expense
        statisticsItem.value.payable.value = payable
        statisticsItem.value.profit_and_loss.value = profit_and_loss
        statisticsItem.value.receivable.value = receivable
        statisticsItem.value.receivable.value = receivable

        receipt_and_payment_data.value.data = receipt_and_payment
        salesData.value.data = sales
    }

    function getDashboardData(query='') {
        network.api('get', `/get/dashboard?company_id=${params.companyId}&module_id=${params.moduleId}`+query)
        .then(res => {
            if(res.status) {
                setValue(res.data)
            }
        })
    }

    //`"start":"","end":""`
    const timeRange = [
        {
            name: 'This Month',
            value: `
            "start":"${firstDateOfMonth}",
            "end":"${lastDateOfMonth}"
            `
        },
        {
            name: 'Last Month',
            value: `
            "start":"${firstDateOfLastMonth}",
            "end":"${lastDateOfLastMonth}"
            `
        },
        {
            name: 'This Quarter',
            value: `
            "start":"${firstDateOfQuarter}",
            "end":"${lastDateOfQuarter}"
            `
        },
        {
            name: 'This Year',
            value: `
            "start":"${firstDateOfYear}",
            "end":"${lastDateOfYear}"
            `
        },
        {
            name: 'Last Year',
            value: `
            "start":"${firstDateOfLastYear}",
            "end":"${lastDateOfLastYear}"
            `
        }

    ]

    return {
        makeStatistics,
        timeRange,
        getDashboardData,
        receipt_and_payment_data,
        salesData,
        statisticsItem,
    }
}