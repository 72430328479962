export default [
    {
        path: '/proadvisor',
        name: 'Proadvisor',
        component: () => import('@/views/page/Proadvisor.vue'),
        meta: {
            requiresAuth: true,
            layout: 'contact'
        }
    }
]