const state = () => ({
	journals:[],
    journal: {}
})

const getters = {
	getJournal: state => state.journal,
	getJournals: state => state.journals
}

const mutations = {
	setJournal: (state, payload) => state.journal = payload,
	setJournals: (state, payload) => state.journals = payload
}

export default {
	namespaced: true,
	state,
	getters,
	mutations
}