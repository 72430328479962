export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/sale-representatives/:pageId',
        component: () => import('@/views/page/company/crm/sr/SRList.vue'),
        name: 'sale-representatives',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/dealer-list/:pageId',
        component: () => import('@/views/page/company/crm/dealer/DealerList.vue'),
        name: 'dealer-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/dealer-import/:pageId',
        component: () => import('@/views/page/company/crm/dealer/dealer-import/DealerImportList.vue'),
        name: 'dealer-import',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/upload-dealer/:pageId',
        component: () => import('@/views/page/company/crm/dealer/dealer-import/UploadDealer.vue'),
        name: 'upload-dealer',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/area-import/:pageId',
        component: () => import('@/views/page/company/crm/area/area-import/AreaImportList.vue'),
        name: 'area-import',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/upload-area/:pageId',
        component: () => import('@/views/page/company/crm/area/area-import/UploadArea.vue'),
        name: 'upload-area',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-calendar/:pageId',
        component: () => import('@/views/page/company/crm/visit-calendar/VisitCalendar.vue'),
        name: 'visit-calendar',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-plan-list/:pageId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitPlanList.vue'),
        name: 'visit-plan-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/my-visit-schedule/:pageId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitPlanList.vue'),
        name: 'my-visit-schedule',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-status/:pageId/update/:planId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitStatusUpdate.vue'),
        name: 'visit-status-update',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/visit-bank-client/:pageId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitBankClient.vue'),
        name: 'visit-bank-client',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/client-status-report/:pageId',
        component: () => import('@/views/page/company/crm/visit-plan/VisitClientStatus.vue'),
        name: 'client-status-report',
        meta: {
            requiresAuth: true
        }
    }
]
