export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/deal-list/:pageId',
        component: () => import('@/views/page/company/deal/List.vue'),
        name: 'deal-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-new-deal/:pageId',
        component: () => import('@/views/page/company/deal/Add.vue'),
        name: 'add-new-deal',
        meta: {
            requiresAuth: true
        }
    },
];