export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-list-allocated-cs/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/AllocatedRequisitionList.vue'),
        name: 'requisition-list-allocated-cs',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-list/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/CSList.vue'),
        name: 'cs-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-approval/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/approval/CSList.vue'),
        name: 'cs-approval',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-approval/:pageId/review',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/approval/View.vue'),
        name: 'cs-review',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-approval/:pageId/details',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/approval/View.vue'),
        name: 'cs-view',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-add/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/CreateCS.vue'),
        name: 'cs-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-proceed/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/ProceedCS.vue'),
        name: 'cs-proceed',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-list-pending/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/PendingCSList.vue'),
        name: 'cs-list-pending',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-print/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/comparative-statement/PrintPreview.vue'),
        name: 'cs-print',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/requisition-list-allocated-sv/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/single-vendor/AllocatedRequisitionListSingleVendor.vue'),
        name: 'requisition-list-allocated-sv',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/quotation-add-sv/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/single-vendor/QuotationCreate.vue'),
        name: 'quotation-add-sv',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/quotation-list/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/single-vendor/QuotationList.vue'),
        name: 'quotation-list',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/quotation-list-pending/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/single-vendor/PendingQuotationList.vue'),
        name: 'quotation-list-pending',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/quotation-proceed/:pageId',
        component: () => import('@/views/page/company/procurement/vendor-selection/single-vendor/ProceedQuotation.vue'),
        name: 'quotation-proceed',
        meta: {
            requiresAuth: true
        }
    },
]
