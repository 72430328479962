export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np-journal-list/:pageId',
        component: () => import('@/views/page/company/ngo/voucher/journal/List.vue'),
        name: 'journal-voucher-np',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np-journal-list/:pageId/create-journal',
        component: () => import('@/views/page/company/ngo/voucher/journal/Create.vue'),
        name: 'create-journal-np',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/np-journal-list/:pageId/edit-journal/:journalId',
        component: () => import('@/views/page/company/ngo/voucher/journal/Edit.vue'),
        name: 'edit-journal-np',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-journal/:pageId/print-journal/:journalId',
        component: () => import('@/views/page/company/ngo/voucher/journal/Print.vue'),
        name: 'ngo-journal-voucher-print',
        meta: {
            requiresAuth: true
        }
    }
]
