export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pending-landed-costing/:pageId',
        component: () => import('@/views/page/company/scm/landed-costing/PendingLandedCostingList.vue'),
        name: 'pending-landed-costing',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/landed-costing-add/:pageId',
        component: () => import('@/views/page/company/scm/landed-costing/LandedCostingAdd.vue'),
        name: 'landed-costing-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/landed-costing/:pageId',
        component: () => import('@/views/page/company/scm/landed-costing/LandedCostingList.vue'),
        name: 'landed-costing-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/landed-costing-edit/:pageId',
        component: () => import('@/views/page/company/scm/landed-costing/LandedCostingEdit.vue'),
        name: 'landed-costing-edit',
        meta: {
            requiresAuth: true
        }
    }
]
