/*
    "start": "2022-01-01",
    "end": "2022-02-28"
*/

import endOfYear from 'date-fns/endOfYear'
import startOfYear from 'date-fns/startOfYear'
import format from 'date-fns/format'
import subYears from 'date-fns/subYears'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import subMonths from 'date-fns/subMonths'
import startOfQuarter from 'date-fns/startOfQuarter'
import lastDayOfQuarter from 'date-fns/lastDayOfQuarter'

const today = new Date()
const dateFormat = 'yyyy-MM-dd'

export default function useDate() {
    const getQuarter = () => {

    }
    const getFirstDateOfMonth = () => {
        return startOfMonth(today)
    }
    const getLastDateOfMonth = () => {
        return endOfMonth(today)
    }
    const getFirstDateOfLastMonth = () => {
        return subMonths(getFirstDateOfMonth(),1)
    }
    const getLastDateOfLastMonth = () => {
        return subMonths(getLastDateOfMonth(),1)
    }
    const getFirstDateOfQuarter = () => {
        return startOfQuarter(today)
    }
    const getLastDateOfQuarter = () => {
        return lastDayOfQuarter(today)
    }
    const getFirstDateOfYear = () => {
        return startOfYear(today)
    }
    const getLastDateOfYear = () => {
        return endOfYear(today)
    }
    const getFirstDateOfLastYear = () => {
        return subYears(getFirstDateOfYear(),1)
    }
    const getLastDateOfLastYear = () => {
        return subYears(getLastDateOfYear(),1)
    }
    const formatDate = (date, isSlashFormat = false) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return isSlashFormat ? formatToDdMmYy(formattedDate, isSlashFormat) : formatToDdMmYy(formattedDate, isSlashFormat) + ' ' + formattedTime
    }

    const formatToDdMmYy = (date, isSlashFormat) => {
        var dat = date.split('-');
        return isSlashFormat ? `${dat[2]}/${dat[1]}/${dat[0]}` : `${dat[2]}-${dat[1]}-${dat[0]}`;
    }

    const formatDateWithTime = (date) => {
        const newDate = new Date(date);

        const options = {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };

        var dateSpl = newDate.toLocaleString('en-US', options).split(/, | /);

        return `${dateSpl[1]} ${dateSpl[0]} ${dateSpl[2]} ${dateSpl[3]} ${dateSpl[4]}`;
    }

    const currentDate = () => {
        const date = new Date();
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }

    const formatForInput = (date) => {
        return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
    }

    return {
        firstDateOfQuarter: format(getFirstDateOfQuarter(), dateFormat),
        lastDateOfQuarter: format(getLastDateOfQuarter(), dateFormat),
        lastDateOfYear: format(getLastDateOfYear(), dateFormat),
        firstDateOfYear: format(getFirstDateOfYear(), dateFormat),
        firstDateOfLastYear: format(getFirstDateOfLastYear(), dateFormat),
        lastDateOfLastYear: format(getLastDateOfLastYear(), dateFormat),
        firstDateOfMonth: format(getFirstDateOfMonth(), dateFormat),
        lastDateOfMonth: format(getLastDateOfMonth(), dateFormat),
        firstDateOfLastMonth: format(getFirstDateOfLastMonth(), dateFormat),
        lastDateOfLastMonth: format(getLastDateOfLastMonth(), dateFormat),
        formatDate,
        formatToDdMmYy,
        formatDateWithTime,
        formatForInput,
        currentDate
    }
}