export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-payment-list/:pageId',
        component: () => import('@/views/page/company/ngo/voucher/payment/List.vue'),
        name: 'ngo-payment-voucher',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-payment/:pageId/create-payment',
        component: () => import('@/views/page/company/ngo/voucher/payment/Create.vue'),
        name: 'ngo-payment-voucher-create',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-payment/:pageId/edit-payment/:paymentId',
        component: () => import('@/views/page/company/ngo/voucher/payment/Edit.vue'),
        name: 'ngo-payment-voucher-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngo-payment/:pageId/print-payment/:paymentId',
        component: () => import('@/views/page/company/ngo/voucher/payment/Print.vue'),
        name: 'ngo-payment-voucher-print',
        meta: {
            requiresAuth: true
        }
    }
]