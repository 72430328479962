export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/journal/List.vue'),
        name: 'journal-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-list/:pageId/create-journal',
        component: () => import('@/views/page/company/core-accounting/voucher/journal/Create.vue'),
        name: 'CreateJournal',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-list/:pageId/edit-journal/:journalId',
        component: () => import('@/views/page/company/core-accounting/voucher/journal/Edit.vue'),
        name: 'EditJournal',
        meta: {
            requiresAuth: true
        }
    },
    //import journal
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-import-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/journal-import/List.vue'),
        name: 'journal-import-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-import/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/journal-import/Create.vue'),
        name: 'journal-import',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/journal-import/:pageId/edit/:uploadLogId',
        component: () => import('@/views/page/company/core-accounting/voucher/journal-import/Create.vue'),
        name: 'journal-import-edit',
        meta: {
            requiresAuth: true
        }
    },
]