export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/payment-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/payment/List.vue'),
        name: 'payment-voucher-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/payment/:pageId/create-payment',
        component: () => import('@/views/page/company/core-accounting/voucher/payment/Create.vue'),
        name: 'CreatePayment',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/payment/:pageId/edit-payment/:paymentId',
        component: () => import('@/views/page/company/core-accounting/voucher/payment/Edit.vue'),
        name: 'EditPayment',
        meta: {
            requiresAuth: true
        }
    }
]