<template>
    <!-- BEGIN: Footer-->
    <footer class="footer footer-static footer-light">
        <p class="clearfix mb-0"><span class="float-md-start d-block d-md-inline-block mt-25">COPYRIGHT &copy; 2022<a class="ms-25"> <router-link to="/" >miaccounts</router-link> </a><span class="d-none d-sm-inline-block">, All rights Reserved</span></span></p>
    </footer>
    <button 
        @click="goToTop" 
        class="btn btn-primary btn-icon scroll-top" 
        type="button"
    >
        <img height="15" src="/app-assets/images/icons/arrow-up.svg" alt="">
    </button>
    <!-- END: Footer-->
</template>

<script>
export default {
    name: 'Footer',
    methods: {
        goToTop () {
            window.scroll({
                top: 5,
                left: 5,
                behavior: 'smooth'
            });
        }
    }
}
</script>
