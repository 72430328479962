import Network from '@/services/network'
import { ref } from 'vue'

export default function handleSidebar() {
    const network = new Network;
    const loading = ref(false)

    const fetchSidebar = () => {
      return network.api('get', '/get/sidebar');
    }
    
    const fetchCompanyModuleByFirstMenu = (company_id, module_id = '') => {
        network.api('get', `/get/sidebar?company_id=${company_id}&module_id=${module_id}`).then(res => {
            if (res.status) {
                const firstMenu = res.data.length > 0 ? res.data[0] : null;
                const moduleId = res.module_id;
                const menuQueryString = !!firstMenu ? `/menu/${firstMenu.id}` : '';
                window.location.href = `/company/${company_id}/module/${moduleId}${menuQueryString}`
            }
        })
    }

    const getModuleUrl = (companyId, module) => {
        const {first_menu_id: firstMenuId} = module;
        const menuQueryString = !!firstMenuId ? `/menu/${firstMenuId}` : '';
        return `/company/${companyId}/module/${module.id}${menuQueryString}`
    }

    return {
        fetchSidebar,
        fetchCompanyModuleByFirstMenu,
        getModuleUrl,
        loading
    }
}