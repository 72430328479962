import { Form, Field, ErrorMessage, defineRule, configure } from 'vee-validate';
import { required, email, min, max, confirmed, digits, alpha_spaces,numeric } from '@vee-validate/rules'; 

export default {
    install (app) {
        app.component('vForm', Form);
        app.component('vField', Field);
        app.component('ErrorMessage', ErrorMessage);

        defineRule('required', required);
        defineRule('confirmed', confirmed);
        defineRule('email', email);
        defineRule('min', min);
        defineRule('max', max);
        defineRule('digits', digits);
        defineRule('numeric', numeric);
        defineRule('alpha_spaces', alpha_spaces);
        defineRule('numeric', numeric);

        configure({
            generateMessage: (ctx) => {
                const messages = {
                    required: `The field ${ctx.field} is required`,
                    confirmed: `The field ${ctx.field} should match`,
                    email: `The field ${ctx.field} must be a valid email`,
                    min: `The field ${ctx.field} is too short`,
                    max: `The field ${ctx.field} is too long`,
                    digits: `The field ${ctx.field} must be digit`,
                    numeric: `The field ${ctx.field} must be number`,
                    alpha_spaces: `The field ${ctx.field} only contains alphabet, space, number`,
                    date_format: `The field ${ctx.field} must give valid format`,
                };

                const message =  messages[ctx.rule.name]
                    ? messages[ctx.rule.name]
                    : `The field ${ctx.field} is invalid`;
                
                return message;
            }
        })
    }
}