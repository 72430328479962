<template>
    <div class="spinner-grow fixed-center z-9999" style="width: 4rem; height: 4rem" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</template>

<script>
export default {
    name: 'Loader'
}
</script>

<style scoped>
    .fixed-center {
        position: fixed;
        top: 50%;
        left: 50%;
    }
</style>
