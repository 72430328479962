export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/campaign-list/:pageId/:accountType',
        component: () => import('@/views/page/company/campaign/List.vue'),
        name: 'campaign',
        meta: {
            requiresAuth: true
        }
    },
]
