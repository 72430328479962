import {ToWords} from "to-words";

export default function figureFormatter () {
    const commaFormat = (value) => {
        if(! value) {
            return 0.00
        }

        return new Intl.NumberFormat('en-IN', {style: 'currency', currency: 'BDT'}).format(value).replace('BDT', '').trim()
    }

    const decimalFormat = (str) => {
        let firstOccuranceIndex = str.search(/\./) + 1; // Index of first occurance of (.)
        let decimalValue = str.substr(0, firstOccuranceIndex) + str.slice(firstOccuranceIndex).replace(/\./g, '');
        return decimalValue.toString().replace(/^[0]/g, '');
    }

    const currencyToWord = (value) => {
        const toWords = new ToWords({
            localeCode: 'en-BD',
            converterOptions: {
                currency: true,
                ignoreDecimal: false,
                ignoreZeroCurrency: false,
                doNotAddOnly: false,
            }
        });
        return toWords.convert(value);
    }

    const onlyCommaFormat = (value) => {
        return value.toLocaleString();
    }

    return {
        commaFormat,
        currencyToWord,
        decimalFormat,
        onlyCommaFormat
    }
}