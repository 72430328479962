export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/voucher-list/:pageId',
        component: () => import('@/views/page/company/core-accounting/voucher/all/List.vue'),
        name: 'voucher-list',
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/vouchars/:pageId/edit-voucher/:paymentId',
        component: () => import('@/views/page/company/core-accounting/voucher/all/Edit.vue'),
        name: 'edit-vouchar-list',
        meta: {
            requiresAuth: true
        }
    },
]