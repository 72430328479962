export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-list/:pageId',
        component: () => import('@/views/page/company/procurement/fd6/List.vue'),
        name: 'fd6-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-add/:pageId',
        component: () => import('@/views/page/company/procurement/fd6/Add.vue'),
        name: 'fd6-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-edit/:pageId/:fd6Id',
        component: () => import('@/views/page/company/procurement/fd6/Add.vue'),
        name: 'fd6-edit',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-budget-list/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-budget/List.vue'),
        name: 'fd6-budget-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-budget-add/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-budget/Add.vue'),
        name: 'fd6-budget-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd6-budget-add/:pageId/:budgetId',
        component: () => import('@/views/page/company/procurement/fd6-budget/Add.vue'),
        name: 'fd6-budget-edit',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/export-fd6/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/Fd6ReportsDownload.vue'),
        name: 'export-fd6',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-detailed-budget/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabDetailedBudget.vue'),
        name: 'ngoab-detailed-budget',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-lifecycle-bva/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabLifecycleBvaReport.vue'),
        name: 'ngoab-lifecycle-bva',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-annexure-gha/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabAnnexureGhaReport.vue'),
        name: 'ngoab-annexure-gha',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-income-budget/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabIncomeBudget.vue'),
        name: 'ngoab-income-budget',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-oh-admin-cost/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabOhAdminCostBudget.vue'),
        name: 'ngoab-oh-admin-cost',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-fixed-assets/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabFixedAssestsBudget.vue'),
        name: 'ngoab-fixed-assets',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-training-cost/:pageId',
        component: () => import('@/views/page/company/procurement/fd6-reports/NgoabTrainingCostBudget.vue'),
        name: 'ngoab-training-cost',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-report-annexure1/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabReportAnnexure1.vue'),
        name: 'ngoab-report-annexure1',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-report-annexure2/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabReportAnnexure2.vue'),
        name: 'ngoab-report-annexure2',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-report-annexure3/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabReportAnnexure3.vue'),
        name: 'ngoab-report-annexure3',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-report-annexure4/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabReportAnnexure4.vue'),
        name: 'ngoab-report-annexure4',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-report-annexure7/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabReportAnnexure7.vue'),
        name: 'ngoab-report-annexure7',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/fd4-annexure/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/Fd4Annexure.vue'),
        name: 'fd4-annexure',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/ngoab-current-period-bva/:pageId',
        component: () => import('@/views/page/company/procurement/ngob-reports/NgoabCurrentPeriodBVA'),
        name: 'ngoab-current-period-bva',
        meta: {
            requiresAuth: true
        }
    }
]