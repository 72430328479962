export default [
    {
        path: '/company/:companyId/module/:moduleId',
        component: () => import('@/views/page/company/Home.vue'),
        name: 'Company',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/dashboard',
        component: () => import('@/views/page/company/Dashboard.vue'),
        name: 'companyDashboard',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId',
        component: () => import('@/views/page/company/Submenu.vue'),
        name: 'CompanySubmenu',
        meta: {
            requiresAuth: true
        }
    }

]