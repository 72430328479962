export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/cs-quotation-approved-list/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/ApprovedCSQuotationList.vue'),
        name: 'cs-quotation-approved-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-add/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/AddNewPO.vue'),
        name: 'po-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-po-list/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/RequisitionPOList.vue'),
        name: 'pr-po-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-po-add/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/AddNewRequisitionPO.vue'),
        name: 'pr-po-add',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/add-new-po/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/AddNewPOrder.vue'),
        name: 'add-new-po',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-list-pending/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/PendingPOList.vue'),
        name: 'po-list-pending',
        meta: {
            requiresAuth: true
        }
    },{
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-proceed/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/ProceedPO.vue'),
        name: 'po-proceed',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-list/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/POList.vue'),
        name: 'po-list',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-approval/:pageId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/approval/POList.vue'),
        name: 'po-approval',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-approval/:pageId/details',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/approval/View.vue'),
        name: 'po-view',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/pr-po-approval/:pageId/details',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/approval/pr-po-view.vue'),
        name: 'pr-po-approval-view',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/new-po-approval/:pageId/details',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/approval/new-po-view.vue'),
        name: 'new-po-approval-view',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-approval/:pageId/review',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/approval/View.vue'),
        name: 'po-review',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/po-register/:pageId',
        component: () => import('@/views/error/UnderConstruction.vue'),
        name: 'po-register',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/purchase-order/:pageId/print/:poId',
        component: () => import('@/views/page/company/procurement/purchase-order/purchase-order-entry/Print.vue'),
        name: 'po-print',
        meta: {
            requiresAuth: true
        }
    },
]
