export default [
    {
        path: '/company/:companyId/module/:moduleId/menu/:menuId/tag-register/:pageId',
        component: () => import('@/views/page/company/scm/tag-register/List.vue'),
        name: 'tag-register',
        meta: {
            requiresAuth: true
        }
    }
]
